$mol_follower $mol_ghost
	Anchor $mol_view
	align /number
		-.5
		-.5
	offset /number
		0
		0
	style *
		^
		transform <= transform \
